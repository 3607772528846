import { useFormContext, Controller } from "react-hook-form";
//mui
import TextField from "@mui/material/TextField";
//component
import useResponsive from "components/hooks/useResponsive"

const FTextField = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          sx={{
            minWidth: 168, pl: 1,
            "& .MuiInputBase-input": {
              background: "white"
            },
          }}
          {...other}
        />
      )}
    />
  );
};

const FTextFieldNumber = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          type="number"
          size="small"
          sx={{ minWidth: 168, pl: 1 }}
          {...other}
        />
      )}
    />
  );
};

const FTextFieldNumberS = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          type="number"
          size="small"
          sx={{ width: 111.4, pl: 1 }}
          {...other}
        />
      )}
    />
  );
};

const FTextFieldStandard = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          variant="standard"
          disabled
          sx={{ minWidth: 168, pl: 1 }}
          {...other}
        />
      )}
    />
  );
};

const FTextFieldOnChange = ({ name, onChange, ...other }) => {
  const { control } = useFormContext();

  // function onChangeRsstF(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          sx={{ minWidth: 168, pl: 1 }}
          onChange={(e) => {
            field.onChange(e)
            onChange(e)
          }}
          {...other}
        />
      )}
    />
  );
};


const FTextFieldS = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          sx={{ width: 111.4, pl: 1 }}
          {...other}
        />
      )}
    />
  );
};

const FTextMultField = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          multiline
          minRows={3}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          sx={{ minWidth: 230.4, pl: 1 }}
          {...other}
        />
      )}
    />
  );
};

const FCustomTextField = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          fullWidth
          //value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          {...other}
        />
      )}
    />
  );
};

// const FTextFieldLabel = ({ name, ...other }) => {
//     const { control } = useFormContext();

//     return (
//         <Controller
//             name={name}
//             control={control}
//             render={({ field, fieldState }) => (
//                 <TextField
//                     {...field}
//                     hiddenLabel
//                     fullWidth
//                     value={typeof field.value == 'number' && field.value == 0 ? '' : field.value}
//                     error={fieldState.invalid}
//                     helperText={fieldState.error?.message}
//                     {...other}
//                 />
//             )}
//         />
//     );
// }

const FTextFieldDate = ({ name, ...other }) => {
  const { control } = useFormContext();

  const isDesktop1 = useResponsive("up", "xs");
  const isDesktop2 = useResponsive("up", "sm");
  const isDesktop3 = useResponsive("up", "md");
  const isDesktop4 = useResponsive("up", "lg");
  const isDesktop5 = useResponsive("up", "xl");

  let selectWidth = 223

  if (isDesktop5) {
    selectWidth = 223
  } else if (isDesktop4) {
    selectWidth = 223
  } else if (isDesktop3) {
    selectWidth = 223
  }
  else if (isDesktop2) {
    selectWidth = 223
  }
  else if (isDesktop1) {
    selectWidth = 160
  }


  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          type="date"
          error={fieldState.invalid}
          helperText={fieldState.error?.message || " "}
          size="small"
          sx={{
            minWidth: selectWidth,
            pl: 1,
            "& .MuiInputBase-input": {
              background: "white"
            },
          }}
          {...other}
        />
      )}
    />
  );
};

export {
  FTextField, FTextFieldNumber, FTextFieldNumberS,
  FTextFieldStandard, FCustomTextField, FTextFieldOnChange,
  FTextFieldDate, FTextMultField, FTextFieldS
};
