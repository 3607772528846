import { useAxios } from "utils/axiosHook";

//Basic認証のURLと重なるのを避けるために、「session」から「sessions」に変更
const useSession = () => {
  const [{ data, loading, error }, api] = useAxios<any, any, any>(
    {
      url: "sessions",
      method: "get",
    },
    { manual: true }
  );

  const getSession = () => {
    return api();
  };

  return { data, loading, error, getSession };
};

const useSignin = () => {
  const [{ data, loading, error }, api] = useAxios<any, any, any>(
    {
      url: "",
      method: "post",
    },
    { manual: true }
  );

  const signin = (username, password) => {
    return api({
      // url: `login`,
      url: `signin`,
      data: {
        username: username,
        password: password,
      },
    });
  };

  return { data, loading, error, signin };
};

const useSignout = () => {
  const [{ data, loading, error }, api] = useAxios<any, any, any>(
    {
      url: "signout",
      method: "get",
    },
    { manual: true }
  );

  const signout = () => {
    return api();
  };

  return { data, loading, error, signout };
};

export { useSession, useSignin, useSignout };
