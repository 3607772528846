import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { MenuButton } from "components/ui/buttons";

import { useSchools } from "api/schools";

const paperStyle = {
  textAlign: "center",
  pt: 3,
};

type _School = {
  id: string;
  name: string;
};

const Page = () => {
  const nav = useNavigate();
  const { getSchools } = useSchools();

  const [schools, setSchools] = useState<Array<_School>>([]);

  useEffect(() => {
    getSchools()
      .then((responseResult) => {
        return responseResult["data"];
      })
      .then((responseData) => {
        const { result, data } = responseData;
        setSchools(data);
      })
      .catch((err) => {});
  }, []);

  // console.log(schools);

  return (
    <React.Fragment>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: "bold", mb: 4 }}
          >
            {"School List"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              "& > :not(style)": {
                m: 1,
              },
            }}
          >
            {schools.map((school) => {
              return (
                <MenuButton
                  onClick={() => nav(`/schools/${school["id"]}/attendances`)}
                >
                  {school["name"]}
                </MenuButton>
                // <Paper
                //   key={school["id"]}
                //   variant="outlined"
                //   sx={{ ...paperStyle }}
                //   onClick={() => {
                //     nav(`/schools/${school["id"]}/attendances`);
                //   }}
                // >
                // </Paper>
              );
            })}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Page;
