// import React, { useEffect, useContext, useState, useCallback } from "react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSession } from "api/auth";

type ISigninInfo = {
  username: String;
  password: String;
};

type IUser = {
  loginId: String;
  role: String;
  schoolId?: number;
};

type IAuthInfo = {
  user?: IUser;
  authenticated?: boolean;
};

type IAuthContext = {
  currentUser?: IAuthInfo;
  setCurrentUser?: React.Dispatch<React.SetStateAction<any>> | null | undefined;
};

const AuthContext = React.createContext<IAuthContext>({});

const useAuth = () => {
  return React.useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const { getSession } = useSession();

  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<IAuthInfo>();

  useEffect(() => {
    getSession()
      .then((responseResult) => {
        const { data } = responseResult;
        return data;
      })
      .then((responseData) => {
        const authInfo = {
          user: responseData,
          authenticated: true,
        };
        setCurrentUser(authInfo);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        const authInfo = {
          authenticated: false,
        };
        setCurrentUser(authInfo);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //ローディング中やcurrentUserがundefinedの場合は、Loadingテキストを表示
  if (loading || currentUser === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth, AuthProvider };
export type { IUser, ISigninInfo, IAuthInfo };
