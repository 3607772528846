import { useAxios } from "utils/axiosHook";

type registerDataType = {
  email: string | null;
  staffNum: string | null;
  password: string | null;
};

//学校一覧取得
const useSchools = () => {
  const [{ data, loading, error }, api] = useAxios<any, any, any>(
    {
      url: "/schools",
      method: "GET",
    },
    { manual: true }
  );

  const getSchools = () => {
    return api();
  };

  return { data, loading, error, getSchools };
};

export { useSchools };
