import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";

import { alpha, styled } from "@mui/material/styles";

import { useSignout } from "api/auth";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const AppBarRootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "rgba(249, 250, 251, 0.72)",
  // [theme.breakpoints.up("lg")]: {
  //   width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  // },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  padding: theme.spacing(0, 5),
  // [theme.breakpoints.up("lg")]: {
  //   minHeight: APPBAR_DESKTOP,
  //   padding: theme.spacing(0, 5),
  // },
}));

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

type Props = {
  onOpenSidebar: () => void;
};

export const Navbar: React.FC<Props> = ({ onOpenSidebar }) => {
  const nav = useNavigate();
  const { signout } = useSignout();

  return (
    <>
      <AppBarRootStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ToolbarStyle>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { lg: "none" }, color: "#637381" }}
              onClick={onOpenSidebar}
            >
              <MenuIcon />
            </IconButton> */}
            {/* <Typography
              color="black"
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Photos
            </Typography> */}
          </ToolbarStyle>
          {/* <ToolbarStyle> */}
          {/* </ToolbarStyle> */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Grid sx={{ pr: 2 }}>
              <Tooltip title="SignOut">
                <IconButton
                  aria-description="asdf"
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    signout().then((res) => {
                      if (res) {
                        nav(0);
                      }
                    });
                  }}
                >
                  <ExitToApp fontSize="medium" sx={{ color: "#637381" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Stack>
        </Stack>
      </AppBarRootStyle>
    </>
  );
};

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
};
function nav(arg0: number) {
  throw new Error("Function not implemented.");
}
