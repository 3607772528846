import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import { axiosEnvConfig } from "config/envConfig";

const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: axiosEnvConfig.baseUrl,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  }),
});

export { useAxios };
