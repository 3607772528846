export const MainColor = "#e4007f";
export const MainDarkColor = "#B80065"

export const tableColor = {
  // 列ヘッダに背景色を指定
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: MainColor,
    color: "#fff",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid #fff`,
  },
  "& .MuiTablePagination-actions": {
    color: MainColor,
  },
  ".MuiDataGrid-toolbarContainer": {
    borderBottom: "solid 1px rgba(224, 224, 224, 1)",
  },
  ".MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)": {
    borderRight: "solid 1px rgba(224, 224, 224, 1) !important",
  },
};

export const disabledButtonColor = {
  "&.Mui-disabled": {
    backgroundColor: MainColor,
    color: "#fff",
  },
  
};
export const beaconTextField = {
  // backgroundColor: 'orange',
  // color: "#fff",
  // boxShadow: 0,
  // border: 1,
  // // borderColor: 'black'
};

export const beaconButtonColor = {
  backgroundColor: "orange",
  color: "#fff",
  boxShadow: 0,
  border: 1,
  // borderColor: 'black'
  ":hover": {
    background: "orange",
    borderColor: "orange",
    boxShadow: 0,
  },
};

export const columsOptions: any = {
  headerAlign: "center",
  align: "center",
  editable: false,
};

export const dialogStyle = {
  boxShadow: 0,
};
