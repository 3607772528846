import * as React from "react";
import * as Yup from "yup"; //https://www.npmjs.com/package/yap
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; //フォームのバリデーションチェック

import { useLocation, useNavigate } from "react-router-dom";
//mui
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container/Container";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, Typography } from "@mui/material";
//parts
import { FProvider, FCustomTextField } from "../../parts/HookForm";
// components
import { CustomButton } from "components/ui/buttons";

import { useSignin } from "api/auth";
import { AxiosResponse } from "axios";
import { useAuth, IUser, ISigninInfo, IAuthInfo } from "contexts/authContext";

//初期値設定
const defaultValues = {
  username: "",
  password: "",
};

const Page = () => {
  //PrivateRouteからNavigate経由でlocationを受け取り、
  //アクセス先を制御する
  const location = useLocation();
  //アクセス先がない場合、トップ画面にリダイレクトする
  const { from } = location.state || { from: { pathname: "/" } };

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const { signin } = useSignin();

  const { setCurrentUser } = useAuth();

  //有効性検査
  const SigninSchema = Yup.object<ISigninInfo>().shape({
    username: Yup.string().required("Please input ID"),
    password: Yup.string().required("Please input password"),
  });

  // 2. useFormで必要な関数を取得し、デフォルト値を指定します。
  const methods = useForm({
    resolver: yupResolver(SigninSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = (params: ISigninInfo) => {
    const { username, password } = params;

    if (username != null || password != null) {
      //   console.log(params);
      signin(username, password)
        .then((res: AxiosResponse) => {
          const { data } = res;

          if (setCurrentUser !== null && setCurrentUser !== undefined) {
            const authInfo: IAuthInfo = {
              user: data,
            };
            setCurrentUser(authInfo);
            navigate(from, { replace: true });
          }
        })
        .catch((err) => {});
    } else {
      // alert(`データを入力してください`);
    }
  };

  //パスワード表示
  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ px: 2.5, py: 2 }}
          >
            <img src={logoSrc} alt="logo" width="80%" />
          </Box> */}
          <Box className="signinForm" sx={{ mt: 1 }}>
            <FProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <FCustomTextField
                name="username"
                label="ID"
                margin="normal"
                autoFocus
              />
              <FCustomTextField
                name="password"
                label="Password"
                margin="normal"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={onClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {"Signin"}
              </CustomButton>
            </FProvider>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Page;
