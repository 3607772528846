import { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom"; // 追加

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  AdminWrapper,
  PrivateWrapper,
  UserWrapper,
} from "contexts/privateRoute";

import Signin from "components/pages/signin";
import Home from "components/pages/home";

import Attendances from "components/pages/attendances";
import Locations from "components/pages/locations";
import Beacon from "components/pages/admin/beacon";
import Schools from "components/pages/admin/schools";
import NotFound from "components/pages/notFound";

import { Navbar } from "components/ui/navbar";

import { AuthProvider } from "contexts/authContext";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color: mianColor,
        },
      },
    },
  },
}));

const AppLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RootStyle>
        <MainStyle>
          <Navbar onOpenSidebar={() => setOpen(true)} />
          <PrivateWrapper />
        </MainStyle>
      </RootStyle>
    </>
  );
};

const UserLayout = () => {
  return (
    <>
      <UserWrapper />
    </>
  );
};
const AdminLayout = () => {
  return (
    <>
      <AdminWrapper />
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CssBaseline />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/signin" element={<Signin />} />
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route element={<UserLayout />}>
              <Route path="attendances" element={<Attendances />} />
              <Route path="locations" element={<Locations/>}/>
            </Route>
            <Route element={<AdminLayout />}>
              <Route path="schools">
                <Route index element={<Schools />} />
                <Route
                  path=":schoolId/attendances"
                  element={<Attendances />}
                ></Route>
                <Route
                  path=":schoolId/locations"
                  element={<Locations />}
                ></Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
