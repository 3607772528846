import { useAxios } from "utils/axiosHook";

//学校内の生徒たちの出席状況を取得
const useAttendances = () => {
  const [{ data, loading, error }, api] = useAxios<any, any, any>(
    {},
    { manual: true }
  );

  const getAttendances = (schoolId: any) => {
    return api({
      url: `/schools/${schoolId}/attendances`,
      method: "GET",
    });
  };

  return { data, loading, error, getAttendances };
};

export { useAttendances };
