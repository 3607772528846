import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useAuth } from "contexts/authContext";

const paperStyle = {
  textAlign: "center",
  pt: 3,
};

const Page = () => {
  const nav = useNavigate();
  const { currentUser } = useAuth();
  const user = currentUser?.user;
  // console.log(user);
  // if (user !== undefined) {
  //   if (user["role"] === "ADMIN") {
  //     nav("/schools");
  //   } else if (user["role"] === "USER") {
  //     nav("/attendances");
  //   }
  // }

  return (
    <>
      {user?.role === "ADMIN" && <Navigate to="/schools" />}
      {user?.role === "USER" && <Navigate to="/attendances" />}
    </>
  );
};

export default Page;
