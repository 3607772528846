import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "contexts/authContext";

export const PrivateWrapper = () => {
  //セッション維持のユーザ情報取
  const { currentUser } = useAuth();

  //アクセス先のパスを収集
  const location = useLocation();

  const user = currentUser?.["user"];

  return !user ? (
    //アクセス先の情報を保持して、ログイン完了後にアクセス先に移るように設定
    <Navigate to="signin" state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export const AdminWrapper = () => {
  const { currentUser } = useAuth();

  //アクセス先のパスを収集
  const location = useLocation();

  const user = currentUser?.["user"];

  return !user ? (
    //アクセス先の情報を保持して、ログイン完了後にアクセス先に移るように設定
    <Navigate to="signin" state={{ from: location }} />
  ) : user["role"] !== "ADMIN" ? (
    <Navigate replace to="/" />
  ) : (
    <Outlet />
  );
};

export const UserWrapper = () => {
  const { currentUser } = useAuth();

  //アクセス先のパスを収集
  const location = useLocation();

  const user = currentUser?.["user"];

  return !user ? (
    //アクセス先の情報を保持して、ログイン完了後にアクセス先に移るように設定
    <Navigate to="signin" state={{ from: location }} />
  ) : user["role"] !== "USER" ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};
