import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Page = () => {
  return (
    <React.Fragment>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: "bold", mb: 4 }}
          >
            {"Not Found Page"}
          </Typography>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Page;
