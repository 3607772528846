import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
//icons
import CircleIcon from "@mui/icons-material/Circle";
import BatteryUnknown from "@mui/icons-material/BatteryUnknown";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";

import { isEmpty, isTextEmpty } from "utils/commons";

import { CustomGridToolbar } from "./hooks/GridToolbar";

import { useAttendances } from "api/attendances";
import { useWidth } from "components/hooks/breakpoint";

import {
  MainColor,
  disabledButtonColor,
  columsOptions,
  tableColor,
} from "assets/css";
import { CustomButton } from "components/ui/buttons";
import { useAuth } from "contexts/authContext";

const FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm:ss";

const Page = () => {
  const nav = useNavigate();
  const breakpoint = useWidth();

  const { currentUser } = useAuth();
  const { schoolId } = useParams();
  const { loading, getAttendances } = useAttendances();
  const [updateIntervalId, setUpdateIntervalId] = useState<any>();

  const [pageSize, setPageSize] = useState(10);

  const [columnVisibilityModel] = useState({
    id: false,
    "attendance.firstReceiveTime": false,
    "attendance.lastReceiveTime": false,
  });

  const [updateTime, setUpdatetime] = useState<string>(
    moment().format(FORMAT_DATE_TIME)
  );

  const [attendances, setAttendances] = useState([]);
  useEffect(() => {
    updateAttendances();
  }, []);

  useEffect(() => {
    // console.log("updateIntervalId", updateIntervalId);

    const updateAttendanceId = setInterval(() => {
      updateNowTime();
      updateAttendances();
    }, 1000 * 30);

    setUpdateIntervalId(updateAttendanceId);

    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      // console.log("beforeunload", updateAttendanceId);
      clearInterval(updateIntervalId);
    });
  }, []);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", ...columsOptions, width: 70 },
    { field: "no", headerName: "ID", ...columsOptions, width: 70 },
    {
      field: "name",
      headerName: "Name",
      ...columsOptions,
      width: 400,
    },
    {
      field: "attendance.stay",
      headerName: "Stay",
      ...columsOptions,
      width: 150,
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 =
      //     param1["api"].getRow(param1["id"])["attendance"]["stay"] ===
      //     "ATTENDED"
      //       ? 1
      //       : 0;
      //   const value2 =
      //     param2["api"].getRow(param2["id"])["attendance"]["stay"] ===
      //     "ATTENDED"
      //       ? 1
      //       : 0;

      //   return value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const stay = attendance["stay"];
        const style = {
          color: "#FFFFFF",
        };
        if (stay === "ATTENDED") {
          style["color"] = "#a0e8c9";
        } else {
          style["color"] = "#d96296";
        }

        return <CircleIcon fontSize="medium" sx={style} />;
      },
    },
    {
      field: "attendance.distance",
      headerName: "Distance",
      ...columsOptions,
      width: 150,
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 = param1["api"].getRow(param1["id"])["attendance"][
      //     "distance"
      //   ];
      //   const value2 = param2["api"].getRow(param2["id"])["attendance"][
      //     "distance"
      //   ];

      //   return value1.localeCompare(value2);
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const distance = attendance["distance"];

        return <Typography>{distance}</Typography>;
      },
    },
    {
      field: "attendance.battery",
      headerName: "Battery",
      ...columsOptions,
      width: 150,
      // getApplyFilterFn: (filterItem: any) => {
      //   if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      //     return null;
      //   }

      //   return (params): boolean => {
      //     return Number(params.value) >= Number(filterItem.value);
      //   };
      // },
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 = param1["api"].getRow(param1["id"])["attendance"][
      //     "battery"
      //   ];
      //   const value2 = param2["api"].getRow(param2["id"])["attendance"][
      //     "battery"
      //   ];

      //   return value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const battery = attendance["battery"];

        let batteryComponent = <BatteryUnknown />;

        if (battery >= 0 && battery < 10)
          batteryComponent = <Battery0BarIcon sx={{ color: "red" }} />;
        else if (battery >= 10 && battery < 20)
          batteryComponent = <Battery1BarIcon sx={{ color: "red" }} />;
        else if (battery >= 20 && battery < 30)
          batteryComponent = <Battery2BarIcon sx={{ color: "orange" }} />;
        else if (battery >= 30 && battery < 40)
          batteryComponent = <Battery3BarIcon sx={{ color: "orange" }} />;
        else if (battery >= 40 && battery < 50)
          batteryComponent = <Battery4BarIcon sx={{ color: "orange" }} />;
        else if (battery >= 50 && battery < 70)
          batteryComponent = <Battery5BarIcon sx={{ color: "green" }} />;
        else if (battery >= 70 && battery < 90)
          batteryComponent = <Battery6BarIcon sx={{ color: "green" }} />;
        else if (battery >= 90 && battery <= 100)
          batteryComponent = <BatteryFullIcon sx={{ color: "green" }} />;

        return batteryComponent;
      },
    },
    {
      field: "attendance.location",
      headerName: "Location",
      ...columsOptions,
      width: 250,
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 = param1["api"].getRow(param1["id"])["attendance"][
      //     "location"
      //   ];
      //   const value2 = param2["api"].getRow(param2["id"])["attendance"][
      //     "location"
      //   ];

      //   if (isTextEmpty(value1)) {
      //     return 1;
      //   } else if (isTextEmpty(value2)) {
      //     return -1;
      //   } else {
      //     return value1.localeCompare(value2);
      //   }
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const location = attendance["location"];

        return <Typography>{location}</Typography>;
      },
    },
    {
      field: "attendance.firstReceiveTime",
      headerName: "First Receive Time",
      ...columsOptions,
      width: 250,
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 = param1["api"].getRow(param1["id"])["attendance"][
      //     "firstReceiveTime"
      //   ];
      //   const value2 = param2["api"].getRow(param2["id"])["attendance"][
      //     "firstReceiveTime"
      //   ];

      //   if (isEmpty(value1)) {
      //     return 1;
      //   } else if (isEmpty(value2)) {
      //     return -1;
      //   } else {
      //     return value1.localeCompare(value2);
      //   }
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const firstReceiveTime = attendance["firstReceiveTime"];

        return <Typography>{firstReceiveTime}</Typography>;
      },
    },
    {
      field: "attendance.lastReceiveTime",
      headerName: "Last Receive Time",
      ...columsOptions,
      width: 250,
      // sortComparator: (v1, v2, param1: any, param2: any) => {
      //   const value1 = param1["api"].getRow(param1["id"])["attendance"][
      //     "lastReceiveTime"
      //   ];
      //   const value2 = param2["api"].getRow(param2["id"])["attendance"][
      //     "lastReceiveTime"
      //   ];
        
      //   if (isEmpty(value1)) {
      //     return 1;
      //   } else if (isEmpty(value2)) {
      //     return -1;
      //   } else {
      //     return value1.localeCompare(value2);
      //   }
      // },
      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const lastReceiveTime = attendance["lastReceiveTime"];

        return <Typography>{lastReceiveTime}</Typography>;
      },
    },
  ];

  const updateNowTime = () => {
    const now = moment();
    const parseNow = now.format(FORMAT_DATE_TIME);

    setUpdatetime(parseNow);
  };

  const updateAttendances = () => {
    const user = currentUser?.user;
    const paramId = user?.role === "ADMIN" ? schoolId : user?.schoolId;

    if (paramId === undefined) return;

    getAttendances(paramId)
      .then((responseResult) => {
        return responseResult["data"];
      })
      .then((responseData) => {
        const { result, time, data } = responseData;

        if (data != null) {
          data.forEach((item, index) => {
            item["no"] = index + 1;
          });
        }

        if (result === "success") {
          setUpdatetime(time);
          setAttendances(data);
        }
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: "bold", mb: 4 }}
          >
            {"Attendance"}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
            mt={3}
          >
            <Typography />
            <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
              <CustomButton
                onClick={() => {
                  const user = currentUser?.user;
                  const paramId = user?.role === "ADMIN" ? schoolId : user?.schoolId;
                  if (paramId === undefined) return;
                  
                  if(user?.role === "ADMIN") nav(`/schools/${paramId}/locations`);
                  else if(user?.role === "USER") nav(`/locations`);
                }}
              >
                {`location`}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  // updateNowTime();
                  // console.log(updateIntervalId);
                  updateAttendances();
                }}
              >
                {`Update time ${updateTime}`}
              </CustomButton>
            </Stack>
          </Stack>
          <DataGrid
            loading={loading}
            rows={attendances}
            rowCount={attendances["length"]}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
              columns: {
                columnVisibilityModel: columnVisibilityModel,
              },
            }}
            disableRowSelectionOnClick
            sx={{
              ...tableColor,
            }}
            slots={{
              toolbar: CustomGridToolbar,
            }}
            //paginationMode="server"
          />
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Page;
