import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

import { MainColor, MainDarkColor } from "assets/css";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(MainColor),
  backgroundColor: theme.palette.primary.main,
  paddingLeft: 20,
  paddingRight: 20,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MenuButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(MainColor),
  backgroundColor: theme.palette.primary.main,
  paddingLeft: 20,
  paddingRight: 20,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export { CustomButton, MenuButton };
