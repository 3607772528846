import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
//icons
import CircleIcon from "@mui/icons-material/Circle";
import BatteryUnknown from "@mui/icons-material/BatteryUnknown";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";

import { CustomGridToolbar } from "./hooks/GridToolbar";

import { useAttendances } from "api/attendances";
import { useWidth } from "components/hooks/breakpoint";

import {
  MainColor,
  disabledButtonColor,
  columsOptions,
  tableColor,
} from "assets/css";
import { CustomButton } from "components/ui/buttons";
import { useAuth } from "contexts/authContext";

const FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm:ss";

const Page = () => {
  const nav = useNavigate();
  const breakpoint = useWidth();

  const { currentUser } = useAuth();
  const { schoolId } = useParams();
  const { loading, getAttendances } = useAttendances();
  const [updateIntervalId, setUpdateIntervalId] = useState<any>();

  const [pageSize, setPageSize] = useState(10);

  const [columnVisibilityModel] = useState({
    id: false,
    "attendance.firstReceiveTime": false,
    "attendance.lastReceiveTime": false,
  });

  const [updateTime, setUpdatetime] = useState<string>(
    moment().format(FORMAT_DATE_TIME)
  );

  const [attendances, setAttendances] = useState([]);
  useEffect(() => {
    updateAttendances();
  }, []);

  useEffect(() => {
    // console.log("updateIntervalId", updateIntervalId);

    const updateAttendanceId = setInterval(() => {
      updateNowTime();
      updateAttendances();
    }, 1000 * 30);

    setUpdateIntervalId(updateAttendanceId);

    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      // console.log("beforeunload", updateAttendanceId);
      clearInterval(updateIntervalId);
    });
  }, []);

  const columns: GridColDef[] = [
    { field: "no", headerName: "ID", ...columsOptions, width: 70 },
    {
      field: "attendance.location",
      headerName: "Location",
      ...columsOptions,
      width: 250,

      renderCell: (params: GridRenderCellParams) => {
        const row = params["row"];
        const attendance = row["attendance"];
        const location = attendance["location"];

        return <Typography>{location}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      ...columsOptions,
      width: 400,
    },
  ];

  const updateNowTime = () => {
    const now = moment();
    const parseNow = now.format(FORMAT_DATE_TIME);

    setUpdatetime(parseNow);
  };

  const updateAttendances = () => {
    const user = currentUser?.user;
    const paramId = user?.role === "ADMIN" ? schoolId : user?.schoolId;

    if (paramId === undefined) return;

    getAttendances(paramId)
      .then((responseResult) => {
        return responseResult["data"];
      })
      .then((responseData) => {
        const { result, time, data } = responseData;
        let filteredData = data;
        if (data != null) {
          console.log(data);
          filteredData = data
            .filter(
              (row) =>
                row.attendance &&
                row.attendance.location != "" &&
                row.attendance.location != null
            )
            .sort((a, b) => {
              // 文字列フィールドの比較
              if (a.attendance.location < b.attendance.location) {
                return -1; // aがbより小さい場合は負の値を返す（昇順）
              }
              if (a.attendance.location > b.attendance.location) {
                return 1; // aがbより大きい場合は正の値を返す（昇順）
              }
              return 0; // aとbが等しい場合は0を返す
            });
          console.log(filteredData);
          filteredData.forEach((item, index) => {
            item["no"] = index + 1;
          });
        }

        if (result === "success") {
          setUpdatetime(time);
          setAttendances(filteredData);
        }
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: "bold", mb: 4 }}
          >
            {"Location"}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
            mt={3}
          >
            <Typography />
            <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
              <CustomButton
                onClick={() => {
                  const user = currentUser?.user;
                  const paramId = user?.role === "ADMIN" ? schoolId : user?.schoolId;
                  if (paramId === undefined) return;
                                    
                  if(user?.role === "ADMIN") nav(`/schools/${paramId}/attendances`);
                  else if(user?.role === "USER") nav(`/attendances`);
                }}
              >
                {`Attendance`}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  // updateNowTime();
                  // console.log(updateIntervalId);
                  updateAttendances();
                }}
              >
                {`Update time ${updateTime}`}
              </CustomButton>
            </Stack>
          </Stack>
          <DataGrid
            loading={loading}
            rows={attendances}
            rowCount={attendances["length"]}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
              columns: {
                columnVisibilityModel: columnVisibilityModel,
              },
            }}
            disableRowSelectionOnClick
            sx={{
              ...tableColor,
            }}
            slots={{
              toolbar: CustomGridToolbar,
            }}
            //paginationMode="server"
          />
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Page;
